<template>
  <v-app class="bg-color">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" align-content="center" justify="center">
          <v-col
            cols="12"
            class="mb-0 pb-0"
            align-self="center"
            justify-self="center"
          >
            <v-card
              elevation="0"
              max-width="350"
              class="mx-auto"
              color="rgba(0, 0, 0, 0)"
            >
              <v-card-text class="mb-0 pb-0">
                <div class="text-center mb-1">
                  <img :src="require('@/assets/img/logo.svg')" width="120" />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-form
              @submit.prevent="login(form)"
              class="login"
              autocomplete="off"
            >
              <v-card
                color="#222325"
                dark
                max-width="350"
                elevation="1"
                class="pt-4 rounded-md mx-auto"
              >
                <v-card-text class="text-center mb-0">
                  <div class="text-h5 font-weight-bold third--text">
                    {{ $t("security.title") }}
                  </div>
                  <div>
                    <span class="body-2">{{ $t("security.description") }}</span>
                  </div>
                </v-card-text>
                <v-card-text class="mb-0 pb-1 px-7">
                  <v-text-field
                    prepend-icon="mdi-account-circle-outline"
                    name="username"
                    v-model="form.username"
                    :label="$t('security.fields.username.title')"
                    type="text"
                    autocomplete="off"
                    class="font-weight-bold"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-lock-outline"
                    name="password"
                    v-model="form.password"
                    :label="$t('security.fields.password.title')"
                    id="password"
                    type="password"
                    autocomplete="off"
                    class="font-weight-bold"
                  ></v-text-field>
                  <v-btn
                    block
                    color="secondary"
                    :loading="loading"
                    type="submit"
                    class="mt-2 mb-5"
                    ><span class="black--text font-weight-bold">{{
                      $t("security.btn.login")
                    }}</span></v-btn
                  >
                </v-card-text>
              </v-card>
              <v-card
                max-width="350"
                elevation="0"
                class="py-0 my-0 mx-auto"
                color="rgba(255,255,255,0)"
              >
                <v-card-text class="text-center py-3 my-0"
                  ><span
                    class="font-weight-bold grey--text text--darken-1 text-body-2"
                    >&copy; LMTGROUP 2021</span
                  ></v-card-text
                >
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    form: {
      username: null,
      password: null,
    },
    loading: false,
  }),
  methods: {
    async login(form) {
      let redirect = this.$auth.redirect();
      let message = "security.errors.bad_credentials";

      // hide old notification
      this.notify({ status: false });

      if (!this.form.username || !this.form.password) {
        this.notify({
          status: true,
          message: this.$t(message),
          right: false,
        });
        return;
      }

      this.loading = true;

      try {
        await this.$auth.login({
          data: form,
          ignoreVueAuth: true,
          redirect: { name: redirect ? redirect.from.name : "dashboard" },
        });
      } catch (error) {
        const status = error.response ? error.response.status : 500;

        if (status !== 401) {
          message = "errors.error_occured";
        }

        this.notify({
          status: true,
          top: true,
          right: false,
          message: this.$t(message),
        });
      }
      this.loading = false;
    },
    ...mapActions({
      notify: "notification/notify",
    }),
  },
};
</script>